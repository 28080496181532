body {
  margin: 0;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.75rem;
}

.gr {
  display: flex;
}

.j-sb {
  justify-content: space-between;
}

.a-c {
  align-items: center;
}

button {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: unset;
}

.notification-error {
  background-color: #ea3038 !important;
}

.notification-success {
  background-color: #67c23a !important;
}

.notification-info {
  background-color: #66b1ff !important;
}

.notification-warning {
  background-color: #e6a23c !important;
}

[data-scrollable] {
  overflow-y: scroll !important;
}

[data-scrollable]::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

[data-scrollable]::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
}

[data-scrollable]::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

sl-menu {
  overflow-y: scroll !important;
}

sl-menu::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

sl-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
}

sl-menu::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

div[aria-label='loading-spinner'] {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}
@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
