* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.home,
.services,
.reports,
.contact,
.events,
.support {
  display: flex;
  margin-left: 260px;
  font-size: 2rem;
}
